<template>
  <v-dialog :value="isShown" max-width="660" @input="closeDialog()">
    <v-card class="d-flex flex-column pa-3">
      <v-icon size="18" class="align-self-end" @click="closeDialog()">
        $close-outlined
      </v-icon>
      <div class="align-self-center font-weight-medium text-body-1 mb-20">
        {{ $t('campaign_statistics.blacklisting.add_sources') }}
      </div>
      <section class="mb-5">
        <div class="border-secondary rounded pa-4">
          <div class="font-weight-medium text-body-2">
            {{ blackListingTitle }}
          </div>
          <div class="height-160 overflow-y-auto">
            <v-chip-group column class="text-caption">
              <v-chip v-for="(item, index) in blackListItems" :key="index" class="mr-1 mb-1 secondary-lighten" small label>
                <span>
                  {{ item[blackListingKey] }}
                </span>
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </section>
      <section class="mb-12">
        <div v-if="selectedCampaigns.length" class="border-secondary rounded pa-4">
          <div class="font-weight-medium text-body-2">
            {{ $t('campaign_statistics.blacklisting.campaign_name') }}
          </div>
          <div class="height-124 overflow-y-auto">
            <v-chip-group column class="text-caption">
              <v-chip v-for="(campaign, index) in allCampaigns.validCampaigns" :key="index" class="mr-1 mb-1 secondary-lighten" small label>
                <span>
                  {{ campaign.name }}
                </span>
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </section>
      <section v-if="allCampaigns.inValidCampaigns.length" class="mb-3">
        <div class="text-body-2 font-weight-medium text-center mb-5">
          {{ invalidCampaignsTitle }}
        </div>
        <div class="border-secondary rounded pa-4">
          <div class="font-weight-medium text-body-2">
            {{ $t('campaign_statistics.blacklisting.campaign_name') }}
          </div>
          <div class="height-124 overflow-y-auto">
            <v-chip-group column class="text-caption">
              <v-chip v-for="(campaign, index) in allCampaigns.inValidCampaigns" :key="index" class="mr-1 mb-1 secondary-lighten" small label>
                <span>
                  {{ campaign.name }}
                </span>
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </section>
      <div>
        <c-btn
          block
          color="primary"
          depressed
          :disabled="!allCampaigns.validCampaigns.length"
          :loading="isLoading"
          :label="$t('campaign_statistics.blacklisting.confirm')"
          @click="addToBlackList()"
        />
        <errors v-if="errors.length" :error-messages="errors" display-all />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { targetsTypes } from '@clickadilla/components/constants/targets-types.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import { campaignsRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'BlackListDialog',
    components: { CBtn, Errors },
    props: {
      isShown: {
        type: Boolean,
        required: true
      },
      blackListItems: {
        type: Array,
        required: true
      },
      blackListingIsSite: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        errors: [],
        isLoading: false
      }
    },
    computed: {
      ...mapState('campaignsStatistics', ['selectedCampaigns']),
      ...mapState('settings', ['adNetwork']),
      ...mapGetters('settings', ['getAdFormatsByAdNetwork']),
      spotTargetFormatsIds() {
        return this.getAdFormatsByAdNetwork.reduce((acc, adFormat) => {
          const hasFormatSpotTarget = adFormat.targetTypes.some((target) => target.type === targetsTypes.SPOT_TARGET)
          if (hasFormatSpotTarget) {
            acc.push(adFormat.id)
          }
          return acc
        }, [])
      },
      allCampaigns() {
        return this.blackListingIsSite
          ? {
            validCampaigns: this.selectedCampaigns.filter((campaign) => !campaign.targets.siteTarget.accept && !campaign.isFlatDeal) || [],
            inValidCampaigns: this.selectedCampaigns.filter((campaign) => campaign.targets.siteTarget.accept || campaign.isFlatDeal) || []
          }
          : {
            validCampaigns: this.selectedCampaigns
              .filter((campaign) => (
                !campaign.isFlatDeal && this.spotTargetFormatsIds.includes(campaign.adFormat.id) && !campaign.targets.spotTarget.accept
              )),
            inValidCampaigns: this.selectedCampaigns
              .filter((campaign) => (
                campaign.isFlatDeal || !this.spotTargetFormatsIds.includes(campaign.adFormat.id) || campaign.targets.spotTarget.accept
              ))
          }
      },
      blackListingTitle() {
        return this.blackListingIsSite
          ? this.$t('campaign_statistics.blacklisting.sites')
          : this.$t('campaign_statistics.blacklisting.spots')
      },
      invalidCampaignsTitle() {
        return this.blackListingIsSite
          ? this.$t('campaign_statistics.blacklisting.sites_cant_added')
          : this.$t('campaign_statistics.blacklisting.spots_cant_added')
      },
      blackListingKey() {
        let targetKey = this.blackListingIsSite ? 'site' : 'tds_id'
        if (this.blackListingIsSite && !this.adNetwork.showSiteDomains) {
          targetKey = 'site_id'
        }
        return targetKey
      }
    },
    methods: {
      closeDialog() {
        this.errors = {}
        this.$emit('close-dialog')
      },
      async addToBlackList() {
        let requestParams
        let requestMethod
        let successNotification

        if (this.blackListingIsSite) {
          requestMethod = campaignsRepository.addBlackListSites
          successNotification = this.$t('campaign_statistics.blacklisting.sites_added')
          const siteDomains = this.blackListItems.filter((site) => !site.site_id).map((site) => site.site)
          requestParams = {
            campaign_ids: this.allCampaigns.validCampaigns.map((campaign) => campaign.id),
            site_ids: this.blackListItems.filter((site) => site.site_id).map((site) => site.site_id),
            ...siteDomains.length && {
              domains: siteDomains
            }
          }
        } else {
          requestMethod = campaignsRepository.addBlackListSpots
          successNotification = this.$t('campaign_statistics.blacklisting.spots_added')
          requestParams = {
            campaign_ids: this.allCampaigns.validCampaigns.map((campaign) => campaign.id),
            spot_ids: this.blackListItems.filter((site) => site.spot_id).map((site) => site.spot_id)
          }
        }

        this.isLoading = true
        try {
          await requestMethod(requestParams)
          this.$showSuccessNotification(successNotification)
          this.$emit('close-dialog')
        } catch (error) {
          const blacklistErrors = Object.values(handleErrors(error))
          this.errors = blacklistErrors.map(
            (errorItem) => (Array.isArray(errorItem) ? errorItem[0] : errorItem)
          )
        }
        this.isLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .height-160 {
    max-height: 160px;
  }
  .height-124 {
    max-height: 124px;
  }
</style>
