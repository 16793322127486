<template>
  <c-autocomplete
    :value="selectedCampaigns"
    :items="mappedCampaigns"
    :loading="campaignsAreLoading"
    :disabled="campaignsAreLoading"
    item-text="text"
    item-value="id"
    :label="$t('campaign_statistics.campaign_name')"
    :placeholder="$t('campaign_statistics.select_campaign_name')"
    return-object
    clearable
    multiple
    hide-details
    :search-input="search"
    @update:search-input="setSearch($event)"
    @change="changeSelectedCampaigns($event)"
  />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'

  export default {
    name: 'Campaigns',
    components: { CAutocomplete },
    props: {
      campaigns: {
        type: Array,
        required: true
      },
      campaignsAreLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        search: ''
      }
    },
    computed: {
      ...mapState('campaignsStatistics', ['selectedCampaigns']),
      mappedCampaigns() {
        return this.campaigns.map((campaign) => ({
          ...campaign,
          text: `${campaign.name} (${campaign.id})`
        }))
      }
    },
    methods: {
      ...mapActions('campaignsStatistics', ['setSelectedCampaigns']),
      changeSelectedCampaigns(selectedCampaigns) {
        this.setSelectedCampaigns(selectedCampaigns)
        this.setSearch('')
        this.$emit('update')
      },
      setSearch(value) {
        this.search = value
      }
    }
  }
</script>
