<template>
  <v-card elevation="0" class="pa-0">
    <v-card-title class="text-h6 black font-weight-medium white--text py-2 pl-6 mb-4">
      {{ $t('campaign_statistics.statistics') }}
      <v-spacer />
    </v-card-title>
    <v-card-text class="py-0 px-5">
      <v-container fluid class="px-0">
        <v-row>
          <v-col cols="12" sm="6" md="3" class="py-2">
            <date-picker-select
              with-presets
              buttons
              :value="dates"
              @input="setDates($event); fetchStatistics()"
              @selected-preset="pushGTMPreset($event)"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" class="py-2">
            <campaigns :campaigns="campaigns" :campaigns-are-loading="campaignsAreLoading" @update="fetchStatistics()" />
          </v-col>
          <v-col cols="12" sm="6" md="3" class="py-2">
            <ad-format @update="fetchStatistics()" />
          </v-col>
          <v-col cols="12" sm="6" md="3" class="py-2">
            <campaign-statuses @update="fetchStatistics()" />
          </v-col>
          <v-col class="pt-4 pb-0 px-3 pt-sm-1 d-flex justify-center justify-sm-end">
            <a
              class="text-decoration-underline text-body-1 text-sm-body-2"
              @click="resetFilters()"
            >
              {{ $t('campaign_statistics.reset_filters') }}
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import DatePickerSelect from '@clickadilla/components/DatePickerSelect/index.vue'
  import gtmPush from '@/services/utils/gtm-push.js'
  import AdFormat from './AdFormat.vue'
  import CampaignStatuses from './CampaignStatuses.vue'
  import Campaigns from './Campaigns.vue'

  export default {
    name: 'Settings',
    components: {
      AdFormat,
      CampaignStatuses,
      Campaigns,
      DatePickerSelect
    },
    props: {
      campaigns: {
        type: Array,
        required: true
      },
      campaignsAreLoading: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      ...mapState('campaignsStatistics', ['dates', 'campaignsStatisticsIsLoading', 'groupBy'])
    },
    methods: {
      ...mapActions('campaignsStatistics', ['fetchStatistics', 'setDates', 'setGroupBy', 'reset']),
      resetFilters() {
        const currentGroup = this.groupBy
        this.reset()
        this.setGroupBy(currentGroup)
        this.fetchStatistics()
      },
      pushGTMPreset(preset) {
        const presetEvents = {
          last_7_days: GTM_EVENTS.L_7DAYS,
          last_30_days: GTM_EVENTS.L_30DAYS,
          last_week: GTM_EVENTS.L_WEEK,
          last_month: GTM_EVENTS.L_MONTH,
          current_week: GTM_EVENTS.C_WEEK,
          current_month: GTM_EVENTS.C_MONTH
        }

        if (!presetEvents[preset]) return

        gtmPush({
          event: presetEvents[preset],
          event_category: GTM_EVENTS_CATEGORY.CALENDAR
        })
      }
    }
  }
</script>
