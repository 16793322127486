<template>
  <div class="my-3 d-flex flex-column flex-sm-row justify-center align-center py-3 border-primary rounded">
    <v-icon class="mr-3 mt-n1" color="error" size="16">
      $blocked
    </v-icon>
    <div class="mr-sm-6 mb-2 mb-sm-0 text-center">
      {{ selectedItemsTitle }}
    </div>
    <c-btn
      :disabled="!selectedItems.length || campaignsAreLoading"
      :loading="campaignsAreLoading"
      :label="$t('campaign_statistics.blacklisting.add_to_blacklist')"
      color="primary"
      depressed
      @click="blackListDialogIsShown = true"
    />
    <black-list-dialog
      :is-shown="blackListDialogIsShown"
      :black-listing-is-site="blackListingIsSite"
      :black-list-items="selectedItems"
      @close-dialog="blackListDialogIsShown = false"
    />
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import BlackListDialog from '@/views/CampaignsStatistics/BlackListing/BlackListDialog.vue'

  export default {
    name: 'BlackListing',
    components: { BlackListDialog, CBtn },
    props: {
      selectedItems: {
        type: Array,
        default: () => []
      },
      blackListingIsSite: {
        type: Boolean,
        required: true
      },
      campaignsAreLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        blackListDialogIsShown: false
      }
    },
    computed: {
      selectedItemsTitle() {
        return this.blackListingIsSite
          ? this.$t('campaign_statistics.blacklisting.add_selected_sources')
          : this.$t('campaign_statistics.blacklisting.add_selected_spots')
      }
    }
  }
</script>
