var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0"}},[(_vm.groupBySite || _vm.groupBySpot)?_c('statistics-search',{attrs:{"placeholder":_vm.$t('campaign_statistics.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-data-table',{attrs:{"loading":_vm.campaignsStatisticsIsLoading,"headers":_vm.headers,"items":_vm.mappedStatisticsItems,"options":_vm.options,"show-select":_vm.blacklisitngIsEnabled,"search":_vm.search,"item-key":_vm.siteKey,"hide-default-footer":"","mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){return _vm.getPageCount($event)}},scopedSlots:_vm._u([(_vm.groupBySite || _vm.groupBySpot)?{key:"item.data-table-select",fn:function({ isSelected, select, item }){return [(item.isSelectable)?_c('v-checkbox',{attrs:{"input-value":isSelected},on:{"change":select}}):_vm._e()]}}:null,{key:"item.src",fn:function({ item }){return [(item.src)?_c('div',{staticClass:"py-3"},[_c('hover-image',{attrs:{"src":item.src}})],1):_vm._e()]}},{key:"item.campaign",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name: 'Campaign',
          params: {
            id: item.campaign_id
          }
        }}},[_vm._v(" "+_vm._s(item.campaign)+" ")])]}},{key:"item.banner",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: _vm.routeNames.AD, params: { id: item.ad_id } }}},[_vm._v(" "+_vm._s(item.banner)+" ")])]}},{key:"item.webPush",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: _vm.routeNames.AD, params: { id: item.ad_id } }}},[_vm._v(" "+_vm._s(item.webPush)+" ")])]}},{key:"item.inStreamAd",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name: _vm.routeNames.AD,
          params: {
            id: item.ad_id
          }
        }}},[_vm._v(" "+_vm._s(item.inStreamAd)+" ")])]}},{key:"item.nativeAd",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name: _vm.routeNames.AD,
          params: {
            id: item.ad_id
          }
        }}},[_vm._v(" "+_vm._s(item.nativeAd)+" ")])]}},{key:"item.directLink",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name: _vm.routeNames.AD,
          params: {
            id: item.ad_id
          }
        }}},[_vm._v(" "+_vm._s(item.directLink)+" ")])]}},{key:"item.iosCalendar",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: _vm.routeNames.AD, params: { id: item.ad_id } }}},[_vm._v(" "+_vm._s(item.iosCalendar)+" ")])]}},{key:"item.site",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.site(item))+" ")]}},{key:"item.ad_type_name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: _vm.routeNames.AD, params: { id: item.ad_id } }}},[_vm._v(" "+_vm._s(item.ad_type_name)+" ")])]}},(_vm.statisticsItems.length)?{key:"body.append",fn:function(){return [_c('total-statistics',{attrs:{"headers":_vm.headers,"items":_vm.totalStatistics,"has-checkboxes":_vm.blacklisitngIsEnabled}})]},proxy:true}:null,{key:"header.reserved_balance",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('campaign_statistics.reserved'))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('c-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","href":_vm.helpUrls.balance_reserved,"target":"_blank","icon-props":{
              icon: 'mdi-help-circle',
              color: 'primary',
              small: 'small'
            }}},'c-btn',attrs,false),on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('campaign_statistics.why_there_is_a_reservation')))])])]},proxy:true}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),(_vm.blacklisitngIsEnabled)?_c('black-listing',{attrs:{"selected-items":_vm.selectedItems,"campaigns-are-loading":_vm.campaignsAreLoading,"black-listing-is-site":_vm.groupBySite}}):_vm._e(),_c('data-table-footer',{attrs:{"items-per-page":_vm.options.itemsPerPage,"current-page":_vm.options.page,"page-count":_vm.pageCount},on:{"change-items-per-page":function($event){return _vm.changeItemsPerPage($event)},"change-current-page":function($event){return _vm.changeCurrentPage($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }